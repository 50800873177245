import React from 'react';
import { Form, Tooltip, message, Select, Input } from 'antd';
import { CustomerSelectModal, Dialog, EditTable, ViewTable, InputNumber, Amount, Layout, Button } from '@/components';
import { FormInstance } from 'antd/lib/form';
import BigNumber from 'bignumber.js';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { hideLoading, showLoading } from '@/components/message/message';
import WebApi from '@/web/api';
import CustomerSaleProductDialog from './CustomerSaleProductDialog';

export default class SaleReturnCreatePage extends React.PureComponent<RouterPropsType> {

  private customerSelectModal: CustomerSelectModal | null = null
  protected form: FormInstance | null = null

  state = {
    loading: false,
    visible: false,
    customerId: '',
    customerName: '',
    products: [],
    orderId: '',
    selectedRowKeys: [],
    selectedRows: [],
    confirmModal: false,
  }

  private remark = '';

  componentDidMount() {
    
  }
  render() {
    const { products, confirmModal, customerId, customerName } = this.state;

    return <Layout.Page>
      <Layout flex1 scrollY>
        <Layout.Title>订单信息</Layout.Title>
        <Form ref={r => this.form = r}>
          <Form.Item label="选择客户" style={{width: 450}} labelCol={{span: 4}} name="customerName" rules={[{
            required: true,
            message: '请选择客户'
          }]}>
            <Select open={false} placeholder="选择客户" onClick={() => {
              this.customerSelectModal?.open();
            }}></Select>
          </Form.Item>
          <Form.Item label="备注说明" style={{width: 900}} labelCol={{span: 2}}>
            <Input placeholder="填写备注" className="underline"/>
          </Form.Item>
        </Form>
        
        <Layout.Title>
          <span>
            <span>商品明细 </span>
            <Tooltip title="从客户历史订单中选择商品退货" placement="right">
              <QuestionCircleOutlined/>
            </Tooltip>
          </span>
        </Layout.Title>
        <EditTable
          rowKey={(row: any) => row.productId || row.id}
          dataSource={products}
          columns={[
            {
              title: '商品',
              width: 200,
              dataIndex: 'productName',
            },
            {
              title: '规格',
              width: 300,
              dataIndex: 'skuName',
            },
            {
              title: '订单价格',
              width: 150,
              align: 'right',
              dataIndex: 'price',
            },
            {
              title: '订单数量',
              width: 150,
              align: 'right',
              dataIndex: 'orderCount',
            },
            {
              title: '订单单位',
              width: 100,
              align: 'center',
              dataIndex: 'orderUnitName',
            },
            {
              title: '订单金额',
              width: 150,
              align: 'right',
              dataIndex: 'payAmount',
            },
            {
              title: '退货价格',
              width: 150,
              align: 'right',
              render: (value, row, index) => {
                if (!row.skuId) {
                  return null;
                }
                return <div>
                  <InputNumber.Price value={row.returnPrice}
                    onChange={value => {
                      const data = [ ...products ];
                      const target: any = data[index];
                      target.returnPrice = value;
                      this.setState({
                        details: data,
                      });
                    }}/>
                </div>
              }
            },
            {
              title: '退货数量',
              width: 150,
              align: 'right',
              render: (value, row, index) => {
                if (!row.skuId) {
                  return null;
                }
                return <InputNumber.Count min={1} value={row.returnCount}
                  onChange={number => {
                    const data = [ ...products ];
                    const target: any = data[index];
                    target.returnCount = number;
                    this.setState({
                      details: data,
                    });
                  }}/>
              }
            },
            {
              title: '退货单位',
              width: 100,
              align: 'center',
              dataIndex: 'unitName',
            },
            {
              title: '退款小计',
              width: 150,
              align: 'right',
              render: (value, row) => {
                if (!row.skuId) {
                  return null;
                }
                return (new BigNumber(row.returnPrice || 0)).multipliedBy(row.returnCount || 0).toFixed(2);
              },
            },
            {
              title: '备注',
              width: 200,
              dataIndex: 'remark',
              render: (v, row, index) => {
                if (!row.skuId) {
                  return null;
                }
                return <Input value={row.remark} onChange={e => {
                  const data = [ ...products ];
                  const target: any = data[index];
                  target.remark = e.target.value;
                  this.setState({
                    details: data,
                  });
                }}/>
              }
            },
          ]}
          onAdd={() => {
            this.form?.validateFields().then(() => {
              this.setState({
                visible: true,
              });
            })
          }}/>
        
      </Layout>
        
      <Layout.FootBar>
        <Button type="primary" onClick={this.clickSave}>保存</Button>
      </Layout.FootBar>

      <CustomerSaleProductDialog visible={this.state.visible} customerId={customerId} customerName={customerName} onOk={this.clickProductOk} onCancel={this.clickCancel}/>

      <CustomerSelectModal ref={r => this.customerSelectModal = r} onOk={(data: any) => {
        this.form?.setFieldsValue({
          customerName: data.customerName
        });
        this.customerSelectModal?.close();
        this.setState({
          customerId: data.customerId,
          customerName: data.customerName,
          products: [],
        })
      }}/>

      <Dialog title="确认退货"
        visible={confirmModal}
        onCancel={() => this.setState({ confirmModal: false})}
        block
        onOk={this.clickConfirmOk}>
        <div>
          <div>
            <ViewTable
              pagination={false}
              rowKey={(row: any) => row.skuId}
              dataSource={products}
              bordered
              columns={[
                {
                  title: '商品',
                  width: 200,
                  dataIndex: 'productName',
                },
                {
                  title: '规格',
                  width: 300,
                  dataIndex: 'skuName',
                },
                {
                  title: '退货价格',
                  width: 100,
                  align: 'right',
                  dataIndex: 'returnPrice'
                },
                {
                  title: '退货数量',
                  width: 100,
                  align: 'right',
                  dataIndex: 'returnCount',
                },
                {
                  title: '计量单位',
                  width: 100,
                  align: 'right',
                  dataIndex: 'unitName',
                },
                {
                  title: '退款小计',
                  width: 100,
                  render: (value, row) => {
                    return (new BigNumber(row.returnPrice)).multipliedBy(row.returnCount).toFixed(2);
                  },
                },
                {
                  title: '备注',
                  width: 200,
                  dataIndex: 'remark',
                },
              ]}/>
          </div>
          <div style={{textAlign: 'right', marginTop: 10}}>
            <strong>退款合计：</strong>
            <Amount>
              {
                products.reduce((p: any, c: any) => {
                  return new BigNumber(c.returnCount).multipliedBy(c.returnPrice).plus(p).toFixed(2);
                }, '0')  
              }
            </Amount>
          </div>
        </div>
      </Dialog>
    </Layout.Page>
  }

  private clickCancel = () => {
    this.setState({
      visible: false,
    });
  }

  clickProductOk = (data) => {
    const products = data.map(item => {
      return {
        ...item,
        returnPrice: item.price,
        returnCount: item.count,
        unitName: item.unitName,
      };
    });

    this.setState({
      visible: false,
      products: [...this.state.products, ...products]
    });
  }

  clickSave = async () => {
    this.form?.validateFields().then((values) => {
      if (this.state.products.length === 0) {
        message.warning('请添加退货商品');
        return;
      }
      this.setState({ confirmModal: true});
      this.remark = values.remark;
    })
    .catch(e => {})
  }

  clickConfirmOk = () => {
    showLoading();
    WebApi.sale_return_order_create({
      customerId: this.state.customerId,
      detailList: this.state.products.map((d: any) => {
        return {
          productId: d.productId,
          productName: d.productName,
          skuId: d.skuId,
          skuName: d.skuName,
          price: d.returnPrice,
          count: Number(d.returnCount),
          remark: d.remark || undefined,
          saleOrderId: d.saleId,
          saleOrderDetailId: d.saleDetailId,
        };
      }) as any,
      remark: this.remark
    })
    .then(() => {
      showSuccess.save();
      this.setState({
        confirmModal: false,
        products: [],
      });
    })
    .finally(() => {
      hideLoading();
    });
  }
}
