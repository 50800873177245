import React, { PureComponent } from 'react';
import ViewTable from '@/components/table/ViewTable';
import { Layout, Dialog, Filter } from '@/components';
import WebApi from '@/web/api';
import LibEnum from 'lib/enum';

interface ICustomerSaleProductDialogProps {
  visible: boolean;
  customerId?: string;
  customerName?: string;
  onOk(data: any[]): void;
  onCancel(): void;
}

interface IState {
  selectedRowKeys: any[];
  selectedRows: any[];
}

export default class CustomerSaleProductDialog extends PureComponent<ICustomerSaleProductDialogProps, IState> {

  private table = React.createRef<ViewTable>();

  private params: any = {};
  
  constructor(props) {
    super(props);

    this.state = {
      selectedRowKeys: [],
      selectedRows: [],
    };
  }

  private close = () => {
    this.setState({
      selectedRows: [],
      selectedRowKeys: [],
    });
    this.props.onCancel();
  }

  render() {
    return <Dialog
      visible={this.props.visible}
      okButtonProps={{
        disabled: this.state.selectedRows.length === 0
      }}
      onOk={this.clickOk}
      block
      destroyOnClose
      onCancel={this.close}
      title="选择商品"
    >
      <Layout flexColumn height overHidden>
        <Filter
          fields={[
            {
              type: 'renderValue',
              field: 'customerId',
              name: '客户',
              renderValue: () => <strong>{this.props.customerName}</strong>
            },
            {
              type: 'category',
            },
            {
              type: 'input',
              placeholder: '商品名称',
              field: 'keyword',
              name: '搜索'
            }
          ]}
          onChange={(values: any) => {
            this.params = values;
            this.table.current?.reset();
          }}
        />

        <ViewTable
          flex1
          ref={this.table}
          fetch={(data) => {
            return WebApi.sale_product_paging({...data, customerId: this.props.customerId, ...this.params});
          }}
          columns={columns as any[]}
          rowSelection={{
            selectedRowKeys: this.state.selectedRowKeys,
            getCheckboxProps: (row) => {
              return {
                disabled: row.orderStatus !== LibEnum.SALE_ORDER_STATUS.FINISHED.value
              };
            },
            onChange: (selectedRowKeys, selectedRows) => {
              this.setState({
                selectedRowKeys,
                selectedRows
              });
            }
          }}
        />
      </Layout>
    </Dialog>
  }

  private clickOk = () => {
    this.props.onOk(this.state.selectedRows);
  }
}

const columns = [
  {
    title: '订货单号',
    width: 160,
    dataIndex: 'saleId',
  },
  {
    title: '订单状态',
    width: 120,
    align: 'center',
    dataIndex: 'orderStatusDesc',
  },
  {
    title: '付款状态',
    width: 120,
    align: 'center',
    dataIndex: 'receivedStatusDesc',
  },
  {
    title: '商品',
    width: 200,
    dataIndex: 'productName',
  },
  {
    title: '规格',
    width: 300,
    dataIndex: 'skuName',
  },
  {
    title: '赠品',
    width: 80,
    dataIndex: 'isGift',
    align: 'center',
    render: v => v ? '是' : '否'
  },
  {
    title: '参与活动',
    width: 200,
    dataIndex: 'activityName',
    render: (v) => v || '未参与'
  },
  {
    title: '订单价格',
    dataIndex: 'price',
    align: 'right',
    width: 100,
  },
  {
    title: '订单数量',
    dataIndex: 'orderCount',
    align: 'right',
    width: 100,
    render: (v, row) => {
      return row.orderCount + ' ' + row.orderUnitName
    },
  },
  {
    title: '结算数量',
    dataIndex: 'count',
    align: 'right',
    width: 100,
    render: (v, row) => {
      return row.count + ' ' + row.unitName
    },
  },
  {
    title: '下单时间',
    dataIndex: 'createdAt',
    align: 'center',
    width: 180,
  },
  {
    title: '供应商',
    dataIndex: 'supplierName',
    align: 'center',
    width: 180,
  },
  {
    title: '进价',
    dataIndex: 'purchasePrice',
    align: 'center',
    width: 180,
  },
  {
    title: '仓库',
    dataIndex: 'repositoryName',
    align: 'center',
    width: 180,
  },
  {
    title: '备注',
    dataIndex: 'remark',
    width: 200,
  },
];